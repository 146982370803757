<template>
  <div class="courseAffrimShopping">
    <div class="courseAffrimShoppingPadding">
      <div class="shoppingBor">
        <span></span>
        <span>支付信息</span>
      </div>
<!--      表格-->
      <div class="shoppingTableFlex">
        <div>
          <span>用户信息</span>
          <div>
            <p  v-if="userName||telNumber">{{userName?userName:telNumber}}</p>
            <p v-if="!userName&&!telNumber">{{userInfo.name?userInfo.name:userInfo.telNumber}}</p>
          </div>
        </div>
        <div>
          <span style="border: 0">订单内容</span>
          <div style="border-left:1px solid #E4E4E4;">
            <el-table
                :data="orderShoppingDetailArr"
                style="width: 100%">
              <el-table-column
                  type="index"
                  label="序号"
                  width="100"
                  :index="indexMethod">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="产品名称"
                  width="260">
              </el-table-column>
              <el-table-column
                  prop="time"
                  label="天数">
              </el-table-column>
              <el-table-column
                  prop="price"
                  label="价格">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div>
          <span style="border: 0">支付方式</span>
          <div style="display: flex;align-items: center;padding: 20px;border-left:1px solid #E4E4E4;">
              <el-radio v-model="payRadio" label="1" border style="display: flex;align-items: center">
                <div style="display: flex;align-items: center">
                  <span style="width: 300px;display: inline-block">微信</span>
                  <img style="width: 34px;margin-left: 66px" src="images/weixin.png">
                </div>
              </el-radio>
            <el-radio v-model="payRadio" label="2" border style="display: flex;align-items: center">
              <div style="display: flex;align-items: center">
                <span style="width: 296px;display: inline-block">支付宝</span>
                <img style="width: 66px;margin-left: 34px" src="images/zhifubao.png">
              </div>
            </el-radio>
          </div>
        </div>
      </div>
<!--      价格-->
      <div class="shoopingPay" >
        <p>总计</p>
        <p style="font-size: 42px;color: #CB000B;margin-left: 20px">¥{{orderShoppingTotalPrice}}</p>
      </div>
      <div class="shoopingPay" >
        <el-button type="primary" @click="createOrder">立即支付</el-button>
      </div>
    </div>
    <template>
      <el-dialog :title="(payRadio===1?'支付宝':'微信') +'支付'" :visible.sync="qrcodeShow" width="250px" :destroy-on-close="true" @opened="openQrCodeImg" >
        <span class="codeimg" ref="qrcodeImg"></span>
      </el-dialog>
      <div ref="aliPay"></div>
    </template>
  </div>
</template>
<script>
import {createTrolleyOrder, findOrderStatus, payAliMoney, payMoney} from "@/api";
import QRCode from "qrcodejs2";
  export default {
    data(){
      return{
        payRadio:'1',
        orderShoppingDetailArr:[],
        orderShoppingTotalPrice:'',
        userInfo:{},
        userName:'',
        telNumber:'',
        orderId:'',
        qrcodeShow: false,
      }
    },
    methods:{
      indexMethod(index) {
        return index+1+'.';
      },
      doPay(){
        if(!this.orderId)return this.$message.error('系统异常');
        if(this.payRadio==1){
          let pay=setInterval(( ) => {
            sessionStorage.setItem("pay", pay);
            let data={
              id:this.orderId
            }
            findOrderStatus(data).then(res=>{
              if(res.data==true){
                this.$message({
                  message: "支付成功",
                  type: "success",
                });
                clearInterval(pay)
              };
            })
          }, 3000)

          this.wxPay(this.orderId);
        }else{
          this.Alipay(this.orderId)
        }
      },
      //微信支付
      wxPay(orderId) {
        let data={
          terminalType:'PC',
          orderId:orderId,
          payType:'WX'
        };
        payMoney(data).then(res=>{
          if(res.code==0){
            this.qrcodeImg =JSON.parse(res.data).code_url;
            this.qrcodeShow = true;

          }
        })
      },
      //支付宝支付
      Alipay(orderId){
        let data={
          terminalType:'PC',
          orderId:orderId,
          payType:'ALI'
        };
        payAliMoney(data).then(res=>{
          this.$refs.aliPay.innerHTML = res;
          document.getElementsByName('punchout_form')[0].submit()
        })
      },
      // 生成二维码
      openQrCodeImg(){
        new QRCode(this.$refs.qrcodeImg, {
          width: 200,
          height: 200,
          text: this.qrcodeImg,
          render: 'canvas'
        });
      },
      // 创建订单
      createOrder(){
        let data={
          terminalType:'WEB',
          price:this.orderShoppingTotalPrice,
          trolleyList:[]
        }
        this.orderShoppingDetailArr.forEach(item=>{
          let obj={}
          obj.id=item.id;
          obj.userId=item.userId;
          obj.goodsPid=item.goodsPid;
          obj.time=item.time;
          obj.price=item.price;
          obj.goodsType='COURSE'
          data.trolleyList.push(obj);
        })
        createTrolleyOrder(data).then(res=>{
          if(res.code==0){
            this.orderId=res.data.id;
            this.doPay();
          }
        })
      }
    },
    mounted() {
      this.userName = this.$cookie.get("userName");
      this.orderShoppingDetailArr=JSON.parse(sessionStorage.getItem('orderShoppingDetailArr'));
      this.orderShoppingTotalPrice=JSON.parse(sessionStorage.getItem('orderShoppingTotalPrice'));
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    },
    computed: {
      storeNickName(){
        this.userName = this.$store.state.userName;
      },
      storeUserPhone(){
        this.telNumber = this.$store.state.telNumber;
      },
      localPhone(){
        let newUserinfo=JSON.parse(localStorage.getItem("userInfo")) ;
        if(newUserinfo&&newUserinfo.name){
          this.userName = newUserinfo.name;
        }else if(newUserinfo&&newUserinfo.telNumber){
          this.telNumber = newUserinfo.telNumber;
        }else if(newUserinfo&&!newUserinfo.telNumber&&!newUserinfo.name&&newUserinfo.teacherType){
          this.telNumber = newUserinfo.account;
        }
      }
    },
    watch:{
      "$route":"getPath",
      // 不要删
      storeUserPhone:{
        handler(item){
        },
        immediate: true,
        deep: true
      },
      // 不要删
      storeNickName:{
        handler(item){

        },
        immediate: true,
        deep: true
      },
      localPhone:{
        handler(item){
        },
        immediate: true,
        deep: true
      },
    },
  }
</script>
<style>
  .courseAffrimShopping{
    background: #F7F7F7;
    padding-top: 120px;
    padding-bottom: 80px;
  }
  .courseAffrimShoppingPadding{
    width: 1200px;
    background: white;
    padding: 24px;
    margin: 0 auto;
  }
  .shoppingBor{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 4px solid #409EFF;
  }
  .shoppingBor>span:first-child{
    display: inline-block;
    background: #00B276;
    width: 6px;
    height: 26px;
    margin-right: 6px;
  }
  .shoppingTableFlex>div{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E4E4;

  }
  .shoppingTableFlex>div>span:first-child{
    border-right:1px solid #E4E4E4;
    display: inline-block;
    padding: 20px;
  }
  .shoppingTableFlex>div>div:last-child{
    padding-left: 20px;
    width: 86%;
    /*border-left:1px solid #E4E4E4;*/
  }
  .courseAffrimShopping .el-radio.is-bordered{
    padding: 4px 20px 0px 10px;
    align-items: center;
  }
  .courseAffrimShopping .el-radio.is-bordered{
    height: 80px;
  }
  .shoopingPay{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0;
  }
</style>
