<template>
  <div class="courserShoppingBg">
    <div class="courseShpad">
      <div class="shoppingTitle">
        <span></span>
        <span>购物车</span>
      </div>
<!--      列表-->
      <div>
        <div class="courseAllchecked">
          <el-checkbox v-model="alllChecked" @change="changeAllChecked()">全选</el-checkbox>
          <span>商品名称</span>
          <span>有效期</span>
          <span>价格</span>
          <span>操作</span>
        </div>
<!--        课程列表-->
        <div style="padding: 34px 0">
          <div class="courserBuyFlexsh" v-for="(item,index) in orderInfoList" :key="index">
          <el-checkbox v-model="item.orderChecked" @change="selectState(item,index)"></el-checkbox>
          <div class="courseBuyFlex">
            <div>
              <img style="width: 138px;margin-right: 20px" :src="item.url">
              <span>{{ item.name }}</span>
            </div>
            <span>{{item.time}}天</span>
            <span style="color: #ABAAAA">¥{{item.price}}</span>
            <span style="cursor: pointer" @click="deleteShopping(item)">删除</span>
          </div>
        </div>
        </div>
      </div>
<!--      结算-->
      <div class="calculatePrice">
        <div>
          <span @click="delAll()">删除所选</span>
          <span @click="clearAll()">清空购物车</span>
        </div>
        <div>
          <span>总计：</span>
          <span>¥{{products}}</span>
          <span @click="goAccounts">去结算</span>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {findTrolley,delTrolley} from "@/api";
  export default {
    data(){
      return{
        alllChecked:false,
        courseChecked:false,
        orderInfoList:[],
        checkedArr:[],
        orderDetailArr:[],
      }
    },
    methods:{
      //去结算
      goAccounts(){
        if(this.orderDetailArr&&this.orderDetailArr.length){
          sessionStorage.setItem('orderShoppingDetailArr',JSON.stringify(this.orderDetailArr));
          sessionStorage.setItem('orderShoppingTotalPrice',JSON.stringify(this.products))
          let routeUrl = this.$router.resolve({
            path: "/courseAfrrimShopping",
          });
          window.open(routeUrl.href);
        }else{
          this.$message('未选商品,不能结算')
        }

      },
      //清空购物车
      clearAll(){
        this.$confirm('此操作将删除所有商品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids=[]
          this.orderInfoList.forEach(item=>{
            ids.push(item.id)
          })
          delTrolley(ids).then(res=>{
            if(res.code==0){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getShoppingList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //清空
      delAll(){
        this.alllChecked=false;
        this.orderInfoList.forEach(item=>{
          item.orderChecked=false
        })
      },
      //全选商品
      changeAllChecked(){
        this.orderInfoList.forEach(item=>{
          if(this.alllChecked==true){
            item.orderChecked=true
          }else{
            item.orderChecked=false
          }
        })
      },
      //查看商品选中状态
      selectState(item,index){
        let _index=this.orderInfoList.findIndex(item=>{
          return !item.orderChecked
        })
        if(_index==-1){
          this.alllChecked=true
        }else{
          this.alllChecked=false
        }
        this.orderDetailArr.push(item)
      },
      //获取购物车列表
      getShoppingList(){
        findTrolley().then(res=>{
          res.data.forEach((item,index)=>{
            this.$set(res.data[index], "orderChecked", false);
          })
          this.orderInfoList=res.data
        })
      },
      //删除购物车
      deleteShopping(item){
        this.$confirm('此操作将删除该商品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids=[]
          ids.push(item.id)
          let that=this
          delTrolley(ids).then(res=>{
            if(res.code==0){
              that.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getShoppingList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    mounted() {
      this.$other.$emit('setIndexNav',-1);
      this.getShoppingList()
    },
    computed: {
      products() {
        var total = 0;
        this.orderInfoList.forEach((item) => {
            if (item.orderChecked) {
              total += item.price;
            }
        });
        return total;
      },
    },
  }
</script>
<style>
  .courserShoppingBg{
    background: #F7F7F7;
    padding-top: 120px;
    padding-bottom: 60px;
  }
  .courseShpad{
    width: 1200px;
    margin: 0 auto;
    background: white;
    padding: 24px;
  }
  .shoppingTitle{
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 40px;
  }
  .shoppingTitle>span:first-child{
    display: inline-block;
    width: 6px;
    height: 26px;
    background: #00B276;
    margin-right: 12px;
  }
  .courseAllchecked{
    border-bottom: 12px solid #F2F2F2;
    padding-bottom: 20px;
  }
  .courseAllchecked>span{
    display: inline-block;
    text-align: center;
  }
  .courseAllchecked>span:nth-child(2){
    width: 540px;
  }
  .courseAllchecked>span:nth-child(3),.courseAllchecked>span:nth-child(4),.courseAllchecked>span:nth-child(5){
    width: 160px;
  }
  .courseBuyFlex,  .courseBuyFlex>div{
    display: flex;
    align-items: center;
  }
  .courseBuyFlex>div{
    width: 528px;
    margin-left: 50px;
  }
  .courseBuyFlex>span:nth-child(2),.courseBuyFlex>span:nth-child(3),.courseBuyFlex>span:nth-child(4){
    display: inline-block;
    width: 160px;
    text-align: center;
  }
  .courserBuyFlexsh{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 20px;
  }
  .courserBuyFlexsh:not(:last-child){
    border-bottom: 2px solid #E4E4E4;
  }
  .calculatePrice{
    background: #F2F2F2;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .calculatePrice>div:first-child>span{
    display: inline-block;
    width: 100px;
    text-align: center;
  }
  .calculatePrice>div:first-child>span:first-child,.calculatePrice>div:first-child>span:nth-child(2){
    cursor: pointer;
  }
  .calculatePrice>div:last-child>span:first-child{
    display: inline-block;
    width: 60px;
    text-align: center;
    color: #ABAAAA;
  }
  .calculatePrice>div:last-child{
    display: flex;
    align-items: center;
  }
  .calculatePrice>div:last-child>span:nth-child(2){
    color: #CB000B;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    width: 140px;
    text-align: center;
  }
  .calculatePrice>div:last-child>span:nth-child(3){
    width: 52px;
    background: #CB000B;
    color: white;
    padding: 12px 20px;
    text-align: center;
    cursor: pointer;
  }
</style>
